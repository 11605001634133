<template>
  <div>
    <b-card class="p-0 mt-4">
      <b-row class="mb-2">
        <b-col md="4">
          <!-- <h4 class="card-title">Daftar Tunggu</h4> -->
          <!-- <strong>TOTAL DATA {{totalData}}</strong> -->
        </b-col>
        <b-col md="8" class="text-right">
          <b-button variant="primary" size="sm" v-if="$store.getters['account/accountData'].role == 'admin'" @click="doBulkSubmitMember()">
            <font-awesome-icon icon="check" ></font-awesome-icon> Ajukan Anggota
          </b-button>
          <b-button variant="primary" size="sm" v-else @click="doBulkVeried()">
            <font-awesome-icon icon="check" ></font-awesome-icon> Setujui Anggota
          </b-button>
        </b-col>
      </b-row>
      <b-form @submit.prevent="doSearch" class="mt-5 mb-5">
        <b-row>
          <b-col md="11">
            <b-row>
              <b-col md="3">
                <b-input
                  placeholder="Cari nama"
                  class="mr-2"
                  v-model="model.name"
                />
              </b-col>
              <b-col md="3">
                <b-input
                  placeholder="Cari Nomor Anggota"
                  class="mr-2"
                  v-model="model.number_id"
                />
              </b-col>
              <b-col md="3">
                <treeselect v-if="this.$store.getters['account/accountData'] != 'admin'" v-model="model.regency_id" :multiple="false" :options="options.regency" placeholder="Pilih Kota/Kabupaten" />
              </b-col>
              <b-col md="3">
                <treeselect v-model="model.district_id" :multiple="false" :options="options.district" placeholder="Pilih Kecamatan" />
              </b-col>
              <!-- <b-col md="3">
                <treeselect v-model="model.village_id" :multiple="false" :options="options.village" placeholder="Pilih Kelurahan" />
              </b-col> -->
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button type="submit" class="ml-2" variant="secondary">Cari</b-button>
          </b-col>
        </b-row>
        <!-- <b-input placeholder="Cari email" v-model="model.email" /> -->
      </b-form>
      <paginated-table
        ref="dataTable"
        :can-delete="true"
        :can-show="true"
        :can-edit="true"
        :can-approve="false"
        :can-reject="false"
        :data-url="apiUrl.member"
        :data-columns="fields"
        :default-data-params="this.$store.getters['account/accountData'].role === 'admin' ? dataParamsAdmin : dataParamsSuperAdmin"
        :data-params="['name', 'created_by', 'is_submited', 'is_verified', 'regency_id', 'district_id', 'number_id']"
        v-on:afterSelected="setSelected"
        v-on:snedTotalData="setTotalData"
      >
      </paginated-table>
    </b-card>
  </div>
</template>
<script>
import PaginatedTable from "@/components/PaginatedTable";
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    PaginatedTable,
    Treeselect
  },
  data() {
    return {
      apiUrl: {
        member: process.env.VUE_APP_API_URL + "members",
      },
      dataParamsAdmin: { 
        is_submited : '0',
        regency_id: this.$store.getters['account/accountData'].regency_id
      },
      dataParamsSuperAdmin: { 
        is_submited: '1',
        is_verified: '0'
      },
      totalData: 0,
      model: {
        name: this.$route.query.name,
        number_id: this.$route.query.number_id,
        role: "Data Angota",
        selectedIds: [],
        regency_id: this.$route.query.district_id,
        district_id: this.$route.query.district_id,
        village_id: this.$route.query.village_id,
      },
      options: {
        regency: [],
        district: [],
        village: [],
      },
      items: [],
      fields: [
        {
          key: "selected",
          label: "",
        },
        {
          key: "nik",
          label: "NIK",
        },
        {
          key: "name",
          label: "Nama",
        },
        {
          key: "gender",
          label: "Jenis Kelamin",
        },
        // {
        //   key: "is_verified",
        //   label: "Status",
        // },
        {
          key: "place_birth",
          label: "Tempat Lahir",
        },
        {
          key: "date_birth",
          label: "Tanggal Lahir",
        },
        {
          key: "regency",
          label: "Kota/Kabupaten",
        },
        {
          key: "district",
          label: "Kecamatan",
        },
        {
          key: "village",
          label: "Kelurahan",
        },
        {
          key: "address",
          label: "Alamat",
        },
        {
          key: "number_phone",
          label: "Nomor Kontak",
        },
        {
          key: "job",
          label: "Pekerjaan",
        },
        {
          key: "genealogy",
          label: "Genealogi",
        },
        {
          key: "actions",
          label: "",
          thClass: "actions",
          class: "text-right",
        },
      ],
    };
  },
  watch: {
    "model.regency_id": function (val) {
      if (val !== undefined) {
        this.getDistrict(val)
      } else {
        this.model.district_id = null
        this.getRegency()
      }
    },
    "model.district_id": function (val) {
      if (val == undefined) {
        this.getDistrict(this.model.regency_id)
      }
    }
  },
  methods: {
    getRegency() {
      this.isLoading = true;
      this.$http.get( this.apiUrl.regency + "?limit=1000&province_id=950b9d3a-c996-4207-97e7-5a2858981979")
        .then((response) => {
          this.options.regency = [];
          response.data.data.forEach((data) => {
            this.options.regency.push({
              id: data.id,
              label: data.name.toUpperCase(),
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    getDistrict(regencyId) {
      this.isLoading = true;
      this.$http.get( this.apiUrl.district + "?limit=1000&regency_id=" + regencyId)
        .then((response) => {
          this.options.district = [];
          response.data.data.forEach((data) => {
            this.options.district.push({
              id: data.id,
              label: data.name.toUpperCase(),
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    doSearch() {
      this.$refs.dataTable.doFilter(this.model);
    },
    getUrlParams() {
      this.model.name = this.model.name !== undefined ? this.model.name : null;
      this.model.email =
        this.model.email !== undefined ? this.model.email : null;
    },
    setSelected (ids) {
      this.model.selectedIds = []
      this.model.selectedIds = ids
    },
    setTotalData (total) {
      this.totalData = total
    },
    doBulkSubmitMember () {
      this.isLoading = true
      this.$swal({
          title: "Anda Yakin?",
          text: "Anggota yang sudah diajukan tidak bisa diubah kembali.",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Sudah",
          cancelButtonText: "Tidak, belum",
          confirmButtonClass: "btn btn-sm btn-success mr-3",
          cancelButtonClass: "btn btn-sm btn-secondary",
          buttonsStyling: false,
      }).then((result) => {
          if (result.value) {
            this.$swal.fire({
                title: 'Mohon Tunggu Sebentar !',
                html: 'Data anggota sedang diajukan.',// add html attribute if you want or remove
                allowOutsideClick: false,
                onBeforeOpen: () => {
                  this.$swal.showLoading()
                  this.$http.post( this.apiUrl.member + "/bulk-submitted", {
                    members: this.model.selectedIds
                  })
                  .then(() => {
                    this.$store.dispatch(
                      "notification/success",
                      "Anggota berhasil diajukan."
                    );
                    this.$swal.close();
                    this.$refs.dataTable.doFilter(this.model);
                  })
                  .catch((error) => {
                      this.isLoading = false
                      this.$swal.close();
                      this.$store.dispatch("notification/error", "Anggota gagal diajukan.");
                      // window.consoleLog(error)
                  });
                },
            });
          }
      });
    },
    doBulkVeried () {
      this.isLoading = true
      this.$swal({
          title: "Anda Yakin?",
          text: "",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Sudah",
          cancelButtonText: "Tidak, belum",
          confirmButtonClass: "btn btn-sm btn-success mr-3",
          cancelButtonClass: "btn btn-sm btn-secondary",
          buttonsStyling: false,
      }).then((result) => {
          if (result.value) {
            this.$swal.fire({
                title: 'Mohon Tunggu Sebentar !',
                html: 'Data anggota sedang diverifikasi.',// add html attribute if you want or remove
                allowOutsideClick: false,
                onBeforeOpen: () => {
                  this.$swal.showLoading()
                  this.$http.post( this.apiUrl.member + "/bulk-verified", {
                    members: this.model.selectedIds
                  })
                  .then(() => {
                    this.$store.dispatch(
                      "notification/success",
                      "Anggota berhasil disetujui."
                    );
                    this.$swal.close();
                    this.$refs.dataTable.doFilter(this.model);
                  })
                  .catch((error) => {
                      this.isLoading = false
                      this.$swal.close();
                      this.$store.dispatch("notification/error", "Anggota gagal disetujui.");
                      // window.consoleLog(error)
                  });
                },
            });
          }
      });
    },
    clearField() {
      this.model.name = null;
      this.model.email = null;
    }
  },
};
</script> 