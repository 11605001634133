<template>
  <div>
    <b-card class="p-0 mt-4">
      <b-row class="mb-2">
        <b-col md="4">
          <!-- <h4 class="card-title">Data Angota</h4> -->
          <!-- <strong>TOTAL DATA {{totalData}}</strong> -->
        </b-col>
        <b-col md="8" class="text-right">

          <b-button variant="success" class="mr-3"  size="sm" v-if="$store.getters['account/accountData'].role === 'super admin'">
            <font-awesome-icon icon="download"></font-awesome-icon> Download KTA
          </b-button>
          <b-button-group class="add-button-group mr-3">
            <b-btn variant="info" size="sm" @click="doExport">
              <font-awesome-icon icon="download" />
              Eksport ke Excel
            </b-btn>
          </b-button-group>
          <b-button
            v-b-popover.top.hover="'Tambah Data Angota'"
            size="sm"
            href="#/members/create"
            variant="primary"
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-col>
      </b-row>
      <b-form @submit.prevent="doSearch" class="mt-5 mb-5">
        <b-row>
          <b-col md="11">
            <b-row>
              <b-col md="3">
                <b-input
                  placeholder="Cari nama"
                  class="mr-2"
                  v-model="model.name"
                />
              </b-col>
              <b-col md="3">
                <b-input
                  placeholder="Cari Nomor Anggota"
                  class="mr-2"
                  v-model="model.number_id"
                />
              </b-col>
              <b-col md="3">
                <treeselect v-if="this.$store.getters['account/accountData'] != 'admin'" v-model="model.regency_id" :multiple="false" :options="options.regency" placeholder="Pilih Kota/Kabupaten" />
              </b-col>
              <b-col md="3">
                <treeselect v-model="model.district_id" :multiple="false" :options="options.district" placeholder="Pilih Kecamatan" />
              </b-col>
              <!-- <b-col md="3">
                <treeselect v-model="model.village_id" :multiple="false" :options="options.village" placeholder="Pilih Kelurahan" />
              </b-col> -->
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button type="submit" class="ml-2" variant="secondary">Cari</b-button>
          </b-col>
        </b-row>
        <!-- <b-input placeholder="Cari email" v-model="model.email" /> -->
      </b-form>
      <paginated-table
        ref="dataTable"
        :can-delete="true"
        :can-show="true"
        :can-edit="true"
        :can-approve="false"
        :can-reject="false"
        :data-url="apiUrl.member"
        :data-columns="fields"
        :default-data-params="this.$store.getters['account/accountData'].role === 'admin' ? dataParamsAdmin : dataParamsSuperAdmin"
        :data-params="['name', 'created_by', 'is_submited', 'is_verified', 'regency_id', 'district_id', 'number_id']"
        v-on:getBackUrlParams="doSetUrlParams"
        v-on:sendTotalData="setTotalData"
      >
      </paginated-table>
    </b-card>
  </div>
</template>
<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import PaginatedTable from "@/components/PaginatedTable";
export default {
  components: {
    Treeselect,
    PaginatedTable,
  },
  data() {
    return {
      apiUrl: {
        member: process.env.VUE_APP_API_URL + "members",
        export: process.env.VUE_APP_API_BASE_URL + "exports/members",
        regency: process.env.VUE_APP_API_URL + "regencies",
        district: process.env.VUE_APP_API_URL + "districts",
        village: process.env.VUE_APP_API_URL + "villages"
      },
      dataParamsAdmin: {
        is_submited: '1',
        is_verified: '1',
        regency_id: this.$store.getters['account/accountData'].regency_id
      },
      dataParamsSuperAdmin: { 
        is_submited: '1',
        is_verified: '1'
      },
      urlParams: null,
      totalData: 0,
      model: {
        name: this.$route.query.name,
        number_id: this.$route.query.number_id,
        role: "Data Angota",
        regency_id: this.$route.query.district_id,
        district_id: this.$route.query.district_id,
        village_id: this.$route.query.village_id,
      },
      options: {
        regency: [],
        district: [],
        village: [],
      },
      items: [],
      fields: [
        {
          key: "selected",
          label: "",
        },
        {
          key: "number_id",
          label: "Nomor Anggota",
        },
        {
          key: "nik",
          label: "NIK",
        },
        {
          key: "name",
          label: "Nama",
        },
        {
          key: "gender",
          label: "Jenis Kelamin",
        },
        // {
        //   key: "is_verified",
        //   label: "Status",
        // },
        {
          key: "place_birth",
          label: "Tempat Lahir",
        },
        {
          key: "date_birth",
          label: "Tanggal Lahir",
        },
        {
          key: "regency",
          label: "Kota/Kabupaten",
        },
        {
          key: "district",
          label: "Kecamatan",
        },
        {
          key: "village",
          label: "Kelurahan",
        },
        {
          key: "address",
          label: "Alamat",
        },
        {
          key: "number_phone",
          label: "Nomor Kontak",
        },
        {
          key: "job",
          label: "Pekerjaan",
        },
        {
          key: "genealogy",
          thClass: "th-genealogy",
          label: "Genealogi",
        },
        // {
        //   key: "status",
        //   isToggle: true,
        //   label: "Status",
        //   thClass: "toggle",
        //   tdClass: "toggle",
        //   toggleFunction: (data) => {
        //     return this.$http.patch(this.apiUrl.member + "/" + data.id, {
        //       status: data.value ? 1 : 0,
        //     });
        //   },
        // },
        {
          key: "actions",
          label: "",
          thClass: "actions",
          class: "text-right",
        },
      ],
    };
  },
  watch: {
    "model.regency_id": function (val) {
      if (val !== undefined) {
        this.getDistrict(val)
      } else {
        this.model.district_id = null
        this.getRegency()
      }
    },
    "model.district_id": function (val) {
      if (val == undefined) {
        this.getDistrict(this.model.regency_id)
      }
    }
  },
  mounted() {
    this.getRegency();
    if (this.$store.getters['account/accountData'].role == 'admin' || this.$route.query.regency_id !== undefined) {
      let regencyId = this.$route.query.regency_id !== undefined ? this.$route.query.regency_id : this.$store.getters['account/accountData'].regency_id
      this.getDistrict(regencyId)
    }
  },
  methods: {
    getRegency() {
      this.isLoading = true;
      this.$http.get( this.apiUrl.regency + "?limit=1000&province_id=950b9d3a-c996-4207-97e7-5a2858981979")
        .then((response) => {
          this.options.regency = [];
          response.data.data.forEach((data) => {
            this.options.regency.push({
              id: data.id,
              label: data.name.toUpperCase(),
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    getDistrict(regencyId) {
      this.isLoading = true;
      this.$http.get( this.apiUrl.district + "?limit=1000&regency_id=" + regencyId)
        .then((response) => {
          this.options.district = [];
          response.data.data.forEach((data) => {
            this.options.district.push({
              id: data.id,
              label: data.name.toUpperCase(),
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    doSearch() {
      this.$refs.dataTable.doFilter(this.model);
    },
    getUrlParams() {
      this.model.name = this.model.name !== undefined ? this.model.name : null
      this.model.number_id = this.model.number_id !== undefined ? this.model.number_id : null
    },
    clearField() {
      this.model.name = null;
      this.model.email = null;
    },
    doSetUrlParams (params) {
      this.urlParams = params
    },
    setTotalData (total) {
      this.totalData = total
    },
    doExport () {
      let url = this.apiUrl.export + this.urlParams
      window.location.href = url
    }
  },
};
</script>