<template>
    <div>
    <b-breadcrumb :items="[{
        text: 'Daftar anggota',
        href: '#/members'
    }, {
        text: 'Detail anggota',
        active: true
    }]"></b-breadcrumb>
    <b-card>
        <template v-if="isLoading">
            <div class="text-center m-5 d-flex justify-content-center">
                <b-spinner variant="success" style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
        </template>
        <template v-else>
            <b-row>
                <b-col md="12">
                    <b-row>
                        <b-col md="6">
                            <!-- <h3 class="page-title mb-0"> {{model.name}}</h3> -->
                        </b-col>
                        <b-col md="6" class="text-right">
                            <b-button v-if="$store.getters['account/accountData'].role === 'super admin' && !model.is_verified" @click="doVerified" size="md" variant="primary" class="mr-3"><font-awesome-icon v-if="isLoading" icon="spinner" spin /><font-awesome-icon icon="check" v-else /> Verifikasi Anggota</b-button>
                            <b-button v-if="$store.getters['account/accountData'].role === 'admin' && !model.is_submited" @click="doSubmited" size="md" variant="primary" class="mr-3"><font-awesome-icon v-if="isLoading" icon="spinner" spin /><font-awesome-icon icon="check" v-else /> Ajukan Anggota</b-button>
                            <b-button v-if="!model.is_submited" :href="'#/members/'+$route.params.id+'/edit'" size="md" variant="success"><font-awesome-icon icon="pencil-alt" /> Edit</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6"> 
                    <b-row class="mt-3" v-if="model.number_id !== null">
                        <b-col md="4">
                            <strong class="text-uppercase">Nomor Anggota</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.number_id}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Nik</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.nik}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Name</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Gender</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.gender}}</span>
                        </b-col>
                    </b-row>
                    <h4 class="mt-5"><strong class="text-uppercase">Kelahiran</strong></h4>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Tempat Lahir</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.place_birth}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Tanggal Lahir</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{getDate(model.date_birth)}}</span>
                        </b-col>
                    </b-row>
                    <h4 class="mt-5"><strong class="text-uppercase">Alamat</strong></h4>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Kota/Kabupaten</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.regency.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Kecamatan</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.district.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Kelurahan/Desa</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.village.name}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Detail Alamat</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.address}}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="6">
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Nomor Kontak</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.number_phone}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Pekerjaan</strong>
                        </b-col>
                        <b-col md="8">
                            <span class="text-uppercase">{{model.job}}</span>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Genealogi</strong>
                        </b-col>
                        <b-col md="8">
                            <ul class="text-uppercase pl-3" v-if="model.genealogy !== null">
                                <li v-for="(genealogy, index) in model.genealogy.split(',')" :key="index">{{genealogy}}</li>
                            </ul>
                            <span v-else>-</span>
                        </b-col>
                    </b-row>
                    <h4 class="mt-5"><strong class="text-uppercase">Unggah</strong></h4>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Foto Diri</strong>
                        </b-col>
                        <b-col md="8">
                            <img :src="model.url_photo_profile" alt="">
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col md="4">
                            <strong class="text-uppercase">Foto ktp</strong>
                        </b-col>
                        <b-col md="8">
                            <img :src="model.url_photo_ktp" alt="" width="300">
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <template v-if="model.is_verified && $store.getters['account/accountData'].role === 'super admin'">
                <h4 class="mt-5"><strong class="text-uppercase">File KTA</strong></h4>
                <b-row class=" aligment-middle">
                    <b-col md="6" class="border p-3">
                        <iframe width="100%" height="400" :src="apiUrl.pdf + '/' + model.id + '/preview'" frameborder="0"></iframe>
                        <!-- <div id="my-kta">
                            <b-img src="/assets/images/kta_washliyah.png" style="max-width: 100%"></b-img>
                            <div style="position: absolute; top: 44%; left: 11%;">
                                <img id="my-photo" :src="model.url_photo_profile" alt="" style="max-width: 53%;">
                            </div>
                            <div class="description-kta" style="position: absolute; top: 44.5%; left: 48%;">
                                <p class="text-uppercase">{{model.number_id}}</p>
                                <p class="text-uppercase">{{model.name}}</p>
                                <p class="text-uppercase">{{model.place_birth}}</p>
                                <p class="text-uppercase">{{getDate(model.date_birth)}}</p>
                                <p class="text-uppercase">{{model.address}}, Kel. {{model.village.name}}, Kec. {{model.district.name}}, {{model.regency.name}}, Sumatera Utara</p>
                            </div>
                            <div class="signature-section">
                                <div class="signature-image">
                                    <img :src="model.signature.path" alt="">
                                </div>
                                <p class="signature-name">{{model.signature.name}}</p>
                                <p class="signature-regency">{{model.signature.name}}</p>
                            </div>
                        </div> -->
                        <div class="text-center mt-3">
                          <!-- <b-button variant="success" @click="doDownloadQRCode(model.id)" class="mr-3">
                            <font-awesome-icon icon="download" />
                            Download KTA
                          </b-button> -->
                          <b-button variant="info" @click="doExportKTA(model.id)">
                            <font-awesome-icon icon="download" />
                            Download ke PDF KTA
                          </b-button>
                        </div>
                    </b-col>
                    <b-col md="6" class="border text-center p-3">
                        <img :src="model.url_qr_code" id="img-qr-code" alt="" width="50%" style="display: flex; margin: 0 auto; margin-top: 8%;">
                        <b-button variant="success" @click="doDownloadQRCode(model.id)" class="mr-3" style="position: absolute; bottom: 20px; left: 36%;">
                            <font-awesome-icon icon="download" />
                            Download QR Code
                        </b-button>
                    </b-col>
                </b-row>
            </template>
            <template v-if="$store.getters['account/accountData'].role == 'super admin'">
                <h4 class="mt-5"><strong class="text-uppercase">Riwayat Anggota</strong></h4>
                <table class="table table-hovered table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>CATATAN</th>
                            <th>TANGGAL</th>
                        </tr>
                    </thead>
                    <tbody v-if="model.memberLogs.length > 0">
                        <tr v-for="log in model.memberLogs" :key="log.id">
                            <td>{{log.note}}</td>
                            <td>{{$moment(log.created_at).format('DD-MM-YYYY hh:mm')}} WIB</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2" class="text-center">TIDAK ADA RIWAYAT UNTUK SAAT INI.</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </template>
    </b-card>
    </div>
</template>
<script>
    import domtoimage from 'dom-to-image';
    export default {
        data () {
            return {
                isLoading: true,
                apiUrl: {
                    member: process.env.VUE_APP_API_URL + 'members',
                    memberLog: process.env.VUE_APP_API_URL + 'member-logs?member_id= ' + this.$route.params.id,
                    signature: process.env.VUE_APP_API_URL + 'signatures',
                    pdf: process.env.VUE_APP_API_BASE_URL + 'kta'
                },
                model: {
                    id: null,
                    memberLogs: [],
                    number_id: null,
                    nik: null,
                    name: null,
                    place_birth: null,
                    date_birth: null,
                    regency_id: null,
                    district_id: null,
                    village: null,
                    regency: null,
                    district: null,
                    address: null,
                    number_phone: null,
                    job: null,
                    genealogy: null,
                    url_photo_ktp: null,
                    url_photo_profile: null,
                    url_qr_code: null,
                    is_verified: null,
                    is_submited: null,
                    signature: {
                        name: null
                    }
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
            this.getLogMember()
        },
        methods: {
            getDetail() {
                this.isLoading = true;
                this.$http.get(this.apiUrl.member + "/" + this.$route.params.id)
                .then((response) => {
                    this.model.id = response.data.id
                    this.model.number_id = response.data.number_id
                    this.model.nik = response.data.nik
                    this.model.name = response.data.name
                    this.model.place_birth = response.data.place_birth
                    this.model.date_birth = response.data.date_birth
                    this.model.district = response.data.village.district
                    this.model.regency = response.data.village.district.regency
                    this.model.village = response.data.village
                    this.model.address = response.data.address
                    this.model.number_phone = response.data.number_phone
                    this.model.job = response.data.job
                    this.model.is_verified = response.data.is_verified
                    this.model.is_submited = response.data.is_submited
                    this.model.gender = response.data.gender
                    this.model.genealogy = response.data.genealogy
                    this.model.url_photo_ktp = response.data.files_photo_ktp['']
                    this.model.url_photo_profile = response.data.files_photo_diri['']
                    this.model.url_qr_code = process.env.VUE_APP_API_BASE_URL_IMAGE + '/' + response.data.url_qr_code
                    if (this.model.is_verified) {
                        //this.getSignature(this.model.regency.id)
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$store.dispatch("notification/error", error);
                    if (
                        typeof error !== "undefined" &&
                        typeof error.response !== "undefined" &&
                        typeof error.response.status !== "undefined" &&
                        error.response.status === 404
                    ) {
                        this.$router.push("/notfound");
                    }
                });
            },
            getLogMember () {
                this.$http.get(this.apiUrl.memberLog)
                .then(response => {
                    this.model.memberLogs = []
                    response.data.data.forEach( data => {
                        this.model.memberLogs.push(data)
                    })
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$store.dispatch("notification/error", error);
                });
            },
            getSignature (regencyId) {
                this.$http.get(this.apiUrl.signature + '/' + regencyId + '/regency')
                .then(response => {
                    this.model.signature.name = response.data.name
                    this.model.signature.regency = response.data.regency.name
                    this.model.signature.path = process.env.VUE_APP_API_BASE_URL_IMAGE + '/storage/' + response.data.signature_path
                    console.log(process.env.VUE_APP_API_BASE_URL_IMAGE + '/storage/' + response.data.signature_path)
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$store.dispatch("notification/error", error);
                });
            },
            getDate(value) {
                return this.$moment(value).format("DD-MM-YYYY");
            },
            doDownloadQRCode () {
                window.location.href = process.env.VUE_APP_API_BASE_URL_IMAGE + '/download-qrcode/' + this.$route.params.id
                // domtoimage.toBlob(document.getElementById('img-qr-code'))
                // .then(function (blob) {
                //     window.saveAs(blob, 'qr-code.png');
                // });
            },
            doExportKTA (id) {
                window.location.href = process.env.VUE_APP_API_BASE_URL_IMAGE + '/kta/' + id + '/export'
            },
            getBase64Image() {
            },
            getBase64FromUrl : async (url) => {
                const data = await fetch(url);
                const blob = await data.blob();
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); 
                    reader.onloadend = () => {
                    const base64data = reader.result;   
                    resolve(base64data);
                    }
                });
            },
            
            doSubmited () {
                this.$swal({
                    title: "Anda Yakin?",
                    text: "Anggota yang sudah diajukan tidak bisa diubah kembali.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ya, Sudah",
                    cancelButtonText: "Tidak, belum",
                    confirmButtonClass: "btn btn-sm btn-success mr-3",
                    cancelButtonClass: "btn btn-sm btn-secondary",
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.isLoading = true
                        this.$http.patch( this.apiUrl.member + "/" + this.$route.params.id + "/submitted", {
                            is_submited: 1
                        })
                        .then(() => {
                            this.$store.dispatch(
                                "notification/success",
                                "Anggota berhasil diajukan."
                            );
                            this.getDetail()
                        })
                        .catch((error) => {
                            this.isLoading = false
                            this.$store.dispatch("notification/error", "Anggota gagal diajukan.");
                            // window.consoleLog(error)
                        });
                    }
                });
            },
            
            doVerified () {
                this.$swal({
                    title: "Anda Yakin?",
                    text: "",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ya, Sudah",
                    cancelButtonText: "Tidak, belum",
                    confirmButtonClass: "btn btn-sm btn-success mr-3",
                    cancelButtonClass: "btn btn-sm btn-secondary",
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.isLoading = true
                        this.$http.patch( this.apiUrl.member + "/" + this.$route.params.id + "/verified", {
                            is_verified: 1
                        })
                        .then(() => {
                            this.$store.dispatch(
                                "notification/success",
                                "Anggota berhasil disetujui."
                            );
                            this.getDetail()
                        })
                        .catch((error) => {
                            this.isLoading = false
                            this.$store.dispatch("notification/error", "Anggota gagal disetujui.");
                            // window.consoleLog(error)
                        });
                    }
                });
            },
        }
    }
</script>