<template>
  <div>
    <b-breadcrumb
      v-if="model.id !== null"
      :items="[
        {
          text: 'Daftar Anggota',
          href: '#/members',
        },
        {
          text: 'Detail Anggota',
          href: '#/members/' + model.id,
        },
        {
          text: 'Ubah Anggota',
          active: true,
        },
      ]"
    ></b-breadcrumb>
    <b-breadcrumb
      v-else
      :items="[
        {
          text: 'Daftar Anggota',
          href: '/#/members',
        },
        {
          text: 'Tambah Anggota',
          active: true,
        },
      ]"
    ></b-breadcrumb>
    <b-card>
      <b-form @submit.prevent="onSubmit" class="custom-form-uppercase" ref="memberForm">
        <b-row>
          <b-col md="6">
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">NIK (Nomor Induk Kependudukan) <sup class="text-danger">*</sup></label>
              <b-form-input
                id="input-1"
                v-model="model.nik"
                placeholder="Ketikkan nik"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Nama <sup class="text-danger">*</sup></label>
              <b-form-input
                id="input-1"
                v-model="model.name"
                placeholder="Ketikkan nama"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="">Jenis Kelamin <sup class="text-danger">*</sup></label>
              <b-form-radio-group
                id="radio-group-1"
                v-model="model.gender"
                :options="options.gender"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Nomor Kontak <sup class="text-danger">*</sup></label>
              <b-form-input
                id="input-1"
                type="text"
                v-model="model.number_phone"
                placeholder="Ketikkan nomor kontak"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Pekerjaan <sup class="text-danger">*</sup></label>
              <b-form-radio-group
                id="pekerjaan"
                v-model="model.job"
                :options="options.job"
                name="pekerjaan"
              ></b-form-radio-group>
              <b-form-input v-model="model.other_job" v-if="showForm" class="mt-3" placeholder="Ketikkan pekerjaan anda"></b-form-input>
              <!-- <treeselect v-model="model.job" :multiple="false" :options="options.job" placeholder="Pilih Pekerjaan" /> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="mt-5"><strong>Kelahiran</strong></h5>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Tempat <sup class="text-danger">*</sup></label>
              <b-form-input
                id="input-1"
                v-model="model.place_birth"
                placeholder="Ketikkan tempat lahir"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Tanggal <sup class="text-danger">*</sup></label>
              <!-- <b-form-datepicker 
                id="datepicker -1"
                v-model="model.date_birth"
                placeholder="Ketikkan tanggal lahir"
                required
              ></b-form-datepicker> -->
              <date-picker
                v-model="model.date_birth"
                format="DD-MM-YYYY"
                type="date"
                placeholder="PILIH TANGGAL LAHIR"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="mt-5"><strong>Genealogi</strong></h5>
            <b-form-group id="input-group-1">
              <label for="">Di likungan Al Wasliyah yang sedang atau pernah diikuti: <sup class="text-danger">*</sup></label>
              <b-form-checkbox-group
                id="genealogi"
                v-model="model.genealogy"
                :options="options.genealogi"
                name="genealogi"
              ></b-form-checkbox-group>
              <!-- <treeselect v-model="model.genealogy" :multiple="false" :options="options.genealogi" placeholder="Pilih Genealogi" /> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="mt-5"><strong>Alamat</strong></h5>
            <b-form-group id="input-group-1" label-for="input-1" v-if="role != 'admin'">
              <label for="">Kota/Kabupaten <sup class="text-danger">*</sup></label>
              <treeselect v-model="model.regency_id" :multiple="false" :options="options.regency" placeholder="Pilih Kota/Kabupaten" />
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Kecamatan <sup class="text-danger">*</sup></label>
              <treeselect v-model="model.district_id" :multiple="false" :options="options.district" placeholder="Pilih Kecamatan" />
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Kelurahan <sup class="text-danger">*</sup></label>
              <treeselect v-model="model.village_id" :multiple="false" :options="options.village" placeholder="Pilih Kelurahan" />
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Detail Alamat <sup class="text-danger">*</sup></label>
              <b-form-textarea
                id="textarea-1"
                rows="3"
                v-model="model.address"
                placeholder="Ketikkan detail alamat"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5 class="mt-5"><strong>Unggah</strong></h5>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Foto Diri <sup class="text-danger">*</sup></label>
              <!-- <b-form-file
                id="file-1"
                v-model="model.file_photo_profile"
                placeholder="Unggah Foto diri"
                :required="model.id === null "
              ></b-form-file> -->
              <my-upload field="img"
                @crop-success="cropSuccess"
                @crop-upload-success="cropUploadSuccess"
              :value.sync="show"
              :langExt="en"
              :width="405"
              :height="555"
              noCircle
              url=""
              :params="params"
              :headers="headers"
              img-format="png"></my-upload>
            <!-- <img :src="imgDataUrl"> -->
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
              <label for="">Kartu Tanda Penduduk (KTP)  <sup class="text-danger">*</sup></label>
              <b-form-file
                id="file-2"
                v-model="model.file_photo_ktp"
                placeholder="Unggah ktp"
                :required="model.id === null "
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary" :disabled="isLoading"
          ><font-awesome-icon v-if="isLoading" icon="spinner" spin />
          Simpan</b-button
        >
      </b-form>
    </b-card>
  </div>
</template>
<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'babel-polyfill'; // es6 shim
import myUpload from 'vue-image-crop-upload';
export default {
  components: { 
    Treeselect,
    DatePicker,
    'my-upload': myUpload
  },
  data() {
    return {
      isLoading: false,
      showForm: false,
      apiUrl: {
        member: process.env.VUE_APP_API_URL + "members",
        regency: process.env.VUE_APP_API_URL + "regencies",
        district: process.env.VUE_APP_API_URL + "districts",
        village: process.env.VUE_APP_API_URL + "villages"
      },
      role: this.$store.getters['account/accountData'].role,
      options: {
        regency: [],
        district: [],
        village: [],
        job: [
          { value: 'GURU/DOSEN PNS', text: 'GURU/DOSEN PNS' },
          { value: 'GURU/DOSEN NON PNS', text: 'GURU/DOSEN NON PNS' },
          { value: 'PNS NON GURU/DOSEN', text: 'PNS NON GURU/DOSEN' },
          { value: 'TNI/POLRI', text: 'TNI/POLRI' },
          { value: 'ANGGOTA LEGISLATIF', text: 'ANGGOTA LEGISLATIF' },
          { value: 'PETANI', text: 'PETANI' },
          { value: 'NELAYAN', text: 'NELAYAN' },
          { value: 'PEDAGANG', text: 'PEDAGANG' },
          { value: 'PELAJAR/MAHASISWA', text: 'PELAJAR/MAHASISWA' },
          { value: 'LAINNYA', text: 'LAINNYA' },
        ],
        genealogi: [
          { value: 'Ikatan Pelajar Al Washliyah', text: 'Ikatan Pelajar Al Washliyah' },
          { value: 'Gerakan Pemuda Al Washliyah', text: 'Gerakan Pemuda Al Washliyah' },
          { value: 'Ikatan Sarjana Al Washliyah', text: 'Ikatan Sarjana Al Washliyah' },
          { value: 'Muslimat Al Washliyah', text: 'Muslimat Al Washliyah' },
          { value: 'Himpunan Mahasiswa Al Washliyah', text: 'Himpunan Mahasiswa Al Washliyah' },
          { value: 'Anggkatan Puteri Al Washliyah', text: 'Anggkatan Puteri Al Washliyah' },
          { value: 'Ikatan Guru dan Dosen Al Washliyah', text: 'Ikatan Guru dan Dosen Al Washliyah' },
          { value: 'Tidak satu pun', text: 'Tidak satu pun' },
        ],
        gender: [
          { text: 'LAKI-LAKI', value: 'LAKI-LAKI' },
          { text: 'PEREMPUAN', value: 'PEREMPUAN' }
        ]
      },
      model: {
        id: null,
        nik: null,
        name: null,
        gender: 'LAKI-LAKI',
        place_birth: null,
        date_birth: null,
        regency_id: this.$store.getters['account/accountData'].role == 'admin' ? this.$store.getters['account/accountData'].regency_id : null,
        district_id: null,
        village_id: null,
        // village: null,
        address: null,
        number_phone: null,
        job: null,
        other_job: null,
        genealogy: [],
        file_photo_profile: null,
        file_photo_ktp: null
      },
      show: false,
			params: {
				token: '123456798',
				name: 'avatar'
			},
			headers: {
				smail: '*_~'
			},
			imgDataUrl: '', // the datebase64 url of created image
      en: {
        hint: 'Klik atau seret file di sini untuk mengunggah',
        loading: 'Mengunggah…',
        noSupported: 'Browser tidak didukung, silakan gunakan IE10+ atau browser lain',
        success: 'Unggah sukses',
        fail: 'Gagal mengunggah',
        preview: 'Pratinjau',
        btn: {
          off: 'Batal',
          close: 'Tutup',
          back: 'Kembali',
          save: 'Simpan'
        },
        error: {
          onlyImg: 'Hanya Image',
          outOfSize: 'Gambar melebihi batas ukuran: ',
          lowestPx: 'Ukuran gambar terlalu kecil. diharapkan setidaknya: '
        }
      }
    };
  },
  watch: {
    "$route.params.id": function (val) {
      if (val !== undefined) {
        this.getDetail();
      } else {
        this.resetForm();
      }
    },
    "model.regency_id": function (val) {
      if (val !== undefined) {
        this.getDistrict(val)
      } else {
        this.getRegency()
      }
    },
    "model.district_id": function (val) {
      console.log(val)
      if (val !== undefined) {
        this.getVillage(val)
      } else {
        this.getDistrict(this.model.regency_id)
      }
    },
    'model.job': function (val) {
      if (val === 'LAINNYA') {
        this.showForm = true
      } else {
        this.showForm = false
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.getDetail();
    }
    this.getRegency();
    if (this.role == 'admin') {
      this.getDistrict(this.$store.getters['account/accountData'].regency_id)
    }
  },
  methods: {
    resetForm() {
    },
    getRegency() {
      this.isLoading = true;
      this.$http.get( this.apiUrl.regency + "?limit=1000&province_id=950b9d3a-c996-4207-97e7-5a2858981979")
        .then((response) => {
          this.options.regency = [];
          response.data.data.forEach((data) => {
            this.options.regency.push({
              id: data.id,
              label: data.name.toUpperCase(),
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    getDistrict(regencyId) {
      this.isLoading = true;
      this.$http.get( this.apiUrl.district + "?limit=1000&regency_id=" + regencyId)
        .then((response) => {
          this.options.district = [];
          response.data.data.forEach((data) => {
            this.options.district.push({
              id: data.id,
              label: data.name.toUpperCase(),
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    getVillage(districtId) {
      this.isLoading = true;
      this.$http.get( this.apiUrl.village + "?limit=1000&district_id=" + districtId)
        .then((response) => {
          this.options.village = [];
          response.data.data.forEach((data) => {
            this.options.village.push({
              id: data.id,
              label: data.name.toUpperCase(),
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    getDetail() {
      this.isLoading = true;
      this.$http
        .get(this.apiUrl.member + "/" + this.$route.params.id)
        .then((response) => {
          this.model.id = response.data.id
          this.model.nik = response.data.nik
          this.model.name = response.data.name
          this.model.gender = response.data.gender
          this.model.place_birth = response.data.place_birth
          this.model.date_birth = response.data.date_birth
          this.model.regency_id = response.data.village.district.regency.id
          this.model.district_id = response.data.village.district.id
          this.model.village_id = response.data.village_id
          // this.model.village = response.data.village
          this.model.address = response.data.address
          this.model.number_phone = response.data.number_phone
          this.model.job = response.data.job
          this.model.genealogy = response.data.genealogy.split(',')
          this.model.file_photo_profile = response.data.file_photo_profile
          this.model.file_photo_ktp = response.data.file_photo_ktp
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
          if (
            typeof error !== "undefined" &&
            typeof error.response !== "undefined" &&
            typeof error.response.status !== "undefined" &&
            error.response.status === 404
          ) {
            this.$router.push("/notfound");
          }
        });
    },
    onSubmit () {
        if (this.isLoading === false) {
          if (this.$refs.memberForm.checkValidity() === false) {
            this.$store.dispatch('notification/error', 'Harap lengkapi semua kolom.')
            return false
          }
          this.isLoading = true
          const formData = new FormData()
          if (this.model.id !== null && this.model.id !== '') {
            formData.append('_method', 'PATCH');
          }
          formData.append('nik', this.model.nik)
          formData.append('name', this.model.name)
          formData.append('gender', this.model.gender)
          formData.append('place_birth', this.model.place_birth)
          formData.append('date_birth', this.$moment(this.model.date_birth).format('YYYY-MM-DD'))
          formData.append('regency_id', this.model.regency_id)
          formData.append('district_id', this.model.district_id)
          formData.append('village_id', this.model.village_id)
          // formData.append('village', this.model.village)
          formData.append('address', this.model.address)
          formData.append('number_phone', this.model.number_phone)
          if (this.model.job !== 'LAINNYA') {
            this.model.job = this.model.other_job !== null? this.model.other_job : this.model.job
            formData.append('job', this.model.job)
          } else if (this.model.job === 'LAINNYA' &&this.model.other_job !== null && this.model.other_job !== ''){
            formData.append('job', this.model.other_job)
          }
          formData.append('genealogy', this.model.genealogy)
          formData.append('file_photo_profile', this.model.file_photo_profile)
          formData.append('file_photo_ktp', this.model.file_photo_ktp)
          this.$http.post(this.apiUrl.member + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData).then((response) => {
            this.isLoading = false
            if (this.model.id !== null && this.model.id !== "") {
              this.$store.dispatch(
                "notification/success",
                "Anggota berhasil diubah."
              );
            } else {
              this.$store.dispatch(
                "notification/success",
                "Anggota berhasil disimpan."
              );
            }
            console.log(response.data)
            // this.$router.push("/members/" + response.data.id);
            this.$router.push("/members-waiting");
          }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
          })
        }
    },
    toggleShow() {
      this.show = !this.show;
    },
          /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field){
      console.log('-------- crop success --------');
      // this.imgDataUrl = imgDataUrl;
      imgDataUrl = this.dataURLtoFile(imgDataUrl, 'image')
      this.model.file_photo_profile = imgDataUrl
      this.$store.dispatch(
        "notification/success",
        "Gambar berhasil disesuaikan."
      );
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field){
      console.log('-------- upload success --------');
      console.log(jsonData);
      console.log('field: ' + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field){
      console.log('-------- upload fail --------');
      console.log(status);
      console.log('field: ' + field);
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }
  },
};
</script>